import { Observable } from 'rxjs';

import { Inject, Injectable } from '../../di';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { A2500ProgramsApi, A4500ProgramsApi, A4000ProgramsApi, A4500ProgramResponse, A4000ProgramResponse } from '@vegga-api-clients/irrigation-control-service';
import { VeggaFacade } from '../vegga-facade';
import { VeggaLoader } from '../../http/veggaLoader';
import { environment } from '../../../environments/environment';
import { HttpClient } from '../../http';

export type ProgramResponse = A4500ProgramResponse | A4000ProgramResponse;
@Injectable('programsFacade')
export class ProgramsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  @Inject('httpClient') private httpClient: HttpClient;
  private programsResponse = new VeggaResponse<ProgramResponse[]>();
  private programByIdResponse = new VeggaResponse<ProgramResponse>();
  private currentProgramResponse = new VeggaResponse<ProgramResponse>();
  private loader = new VeggaLoader();
  private A2500ProgramsApi: A2500ProgramsApi;
  private A4500ProgramsApi: A4500ProgramsApi;
  private A4000ProgramsApi: A4000ProgramsApi;
  private agronicAPIBasePath = environment.AGRONICWEB_API_URL;

  constructor() {
    this.A2500ProgramsApi = new A2500ProgramsApi();
    this.A4500ProgramsApi = new A4500ProgramsApi();
    this.A4000ProgramsApi = new A4000ProgramsApi();
    this.A2500ProgramsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.A4500ProgramsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.A4000ProgramsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }

  get programs$(): Observable<any> {
    return this.programsResponse.value$;
  }

  get programById$(): Observable<any> {
    return this.programByIdResponse.value$;
  }

  get currentProgram$(): Observable<ProgramResponse> {
    return this.currentProgramResponse.value$;
  }

  set currentProgram(program: ProgramResponse) {
    this.currentProgramResponse.set(program);
  }

  getA2500Programs(id: number, isRefreshing = false) {
    const req$ = this.A2500ProgramsApi.getProgramList({ id });

    const subscription = handleResponse(req$, this.programsResponse).subscribe({
      next: programs => {
        this.programsResponse.set(programs);
      },
      error: err => {
        this.programsResponse.setError(err, {});
      },
    });

    if (!isRefreshing) {
      this.loader.waitFor(subscription);
    }
  }

  getABITPrograms(deviceId: number, isRefreshing = false) {
    const req$ = this.httpClient.get<any>(`${this.agronicAPIBasePath}/api/v1/units/${deviceId}/programs`, {});

    const subscription = handleResponse(req$, this.programsResponse).subscribe({
      next: programs => {
        this.programsResponse.set(programs);
      },
      error: err => {
        this.programsResponse.setError(err, {});
      },
    });

    if (!isRefreshing) {
      this.loader.waitFor(subscription);
    }
  }

  getA4000Programs(id: number) {
    const req$ = this.A4000ProgramsApi.getProgramListA4000({ id });

    const subscription = handleResponse(req$, this.programsResponse).subscribe({
      next: programs => {
        this.programsResponse.set(programs);
      },
      error: err => {
        this.programsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  getA4500Programs(id: number) {
    const req$ = this.A4500ProgramsApi.getProgramListA4500({ id });

    const subscription = handleResponse(req$, this.programsResponse).subscribe({
      next: programs => {
        this.programsResponse.set(programs);
      },
      error: err => {
        this.programsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  getA4000Program(deviceId: number, programId: number) {
    const req$ = this.A4000ProgramsApi.getProgramByIdA4000({
      id: deviceId,
      programId,
    });
    const subscription = req$.subscribe({
      next: program => {
        this.programByIdResponse.set(program);
      },
      error: err => {
        this.programByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  getA4500Program(deviceId: number, programId: number, isRefreshing = false) {
    const req$ = this.A4500ProgramsApi.getProgramByIdA4500({
      id: deviceId,
      programId,
    });
    const subscription = handleResponse(req$, this.programByIdResponse).subscribe({
      next: program => {
        this.programByIdResponse.set(program);
      },
      error: err => {
        this.programByIdResponse.setError(err, {});
      },
    });

    if (!isRefreshing) {
      this.loader.waitFor(subscription);
    }
  }

  clearProgramsResponse() {
    this.programsResponse.clear();
  }

  clearProgramResponse() {
    this.programByIdResponse.clear();
  }
}
