import { DataExportApi, ExportCatalogItem, ExportCreationRequest, ExportDetailsResponse } from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { VeggaFacade } from '../vegga-facade';
import { HttpErrorResponse } from '../../http';

@Injectable('exportsFacade')
export class ExportsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private exportsResponse = new VeggaResponse<ExportDetailsResponse[], HttpErrorResponse>();
  private exportByIdResponse = new VeggaResponse<ExportDetailsResponse, HttpErrorResponse>();
  private exportFileResponse = new VeggaResponse();
  private catalogResponse = new VeggaResponse<ExportCatalogItem[]>();
  private dataExportApi: DataExportApi;
  private loader = new VeggaLoader();

  constructor() {
    this.dataExportApi = new DataExportApi();
    this.dataExportApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get isGlobalLoading$(): Observable<boolean> {
    return this.veggaFacade.loader.isLoading$;
  }

  get catalog() {
    return this.catalogResponse.value;
  }

  get catalog$(): Observable<ExportCatalogItem[]> {
    return this.catalogResponse.value$;
  }

  get exports$(): Observable<ExportDetailsResponse[]> {
    return this.exportsResponse.value$;
  }

  get exportsError$(): Observable<HttpErrorResponse> {
    return this.exportsResponse.error$;
  }

  get exports(): ExportDetailsResponse[] {
    return this.exportsResponse.value;
  }

  get exportById$(): Observable<ExportDetailsResponse> {
    return this.exportByIdResponse.value$;
  }

  get exportByIdError$(): Observable<HttpErrorResponse> {
    return this.exportByIdResponse.error$;
  }

  get exportFile() {
    return this.exportFileResponse;
  }

  loadCatalog() {
    const req$ = this.dataExportApi.getirrigationExportCatalog();

    const subscription = handleResponse(req$, this.catalogResponse).subscribe({
      next: fert => {
        this.catalogResponse.set(fert);
      },
      error: err => {
        this.catalogResponse.setError(err, {});
      },
    });

    this.veggaFacade.loader.waitFor(subscription);
  }

  loadExports() {
    const req$ = this.dataExportApi.listIrrigationExports();

    const subscription = handleResponse(req$, this.exportsResponse).subscribe({
      next: res => {
        this.exportsResponse.set(res);
      },
      error: err => {
        this.exportsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadExportById(id: string) {
    const req$ = this.dataExportApi.getIrrigationExport({ id });

    const subscription = req$.subscribe({
      next: res => {
        this.exportByIdResponse.set(res);
      },
      error: (err: HttpErrorResponse) => {
        this.exportByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  getExportFile(id: string): Observable<Blob> {
    return this.dataExportApi.getIrrigationExportFile({ id });
  }

  createExport(exportData: ExportCreationRequest): Observable<ExportDetailsResponse> {
    return this.dataExportApi.createIrrigationExport({ exportCreationRequest: exportData });
  }

  deleteExport(id: string) {
    return this.dataExportApi.deleteIrrigationExport({ id: id }).pipe(tap(() => this.exportsResponse.set(this.exports.filter((exp: ExportDetailsResponse) => exp.id !== id))));
  }

  clearExportsError(): void {
    this.exportsResponse.clearError();
  }

  clearExportByIdError(): void {
    this.exportByIdResponse.clearError();
  }
}
