//@ts-ignore: In-browser module import
import { HttpEvent } from '@vegga-api-clients/irrigation-control-service';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpMethod } from '../http';
import { HttpRequest } from '../http/request';

/**
 * TODO: Remove when agronic is finally gone
 * This interceptor is duplicated, one is used when request uses
 * vegga httpclient (agronic requests), and the other when request is made using vegga-api-clients
 * All changes must be applied to the ServerMessageInterceptor in scripts repo
 */
export interface VeggaToastConfig {
  message: string;
  statusClass: string;
}

const EXCLUDED_PATHS = [
  'io/status'
];

const EXCLUDED_PATH_ERRORS = [
  '/exports/irrigation/'
]

export class ServerMessageInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return (next.handle(req) as Observable<HttpEvent<unknown>>).pipe(
      tap(() => {
        const isExcluded = EXCLUDED_PATHS.some(excludedPath => req.url.includes(excludedPath));
        if (isExcluded) {
          return;
        }

        switch (req.method) {
          case HttpMethod.POST:
          case HttpMethod.PUT:
            this.showVeggaToast({ message: 'Guardado con éxito', statusClass: 'success' });
            break;
          case HttpMethod.DELETE:
            this.showVeggaToast({ message: 'Eliminado con éxito', statusClass: 'success' });
            break;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (EXCLUDED_PATH_ERRORS.some(path => req.url.includes(path))) {
          const message = (error.error && error.error.errorCode) || 'Se ha producido un error'
          this.showVeggaToast({ message, statusClass: 'error' });
        }
        return throwError(() => of(error));
      }))

  }

  /**
   * Shows a toast with passed message and style
   */
  private showVeggaToast({ message, statusClass }: VeggaToastConfig): void {
    const toast = document.querySelector('vegga-toast-manager');
    (toast as any).create({ message, statusClass })
  }
}