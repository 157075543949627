import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Inject, Injectable } from "../../di";
import { HttpClient } from "../../http";
import { VeggaResponse } from "../../http/veggaResponse";
import { handleResponse } from "../common.facade";


@Injectable("cropsFacade")
export class CropsFacade {
  private cropsResponse = new VeggaResponse();
  private agronicAPIBasePath = environment.AGRONICWEB_API_URL;
  @Inject("httpClient") private httpClient: HttpClient;

  constructor() {}

  get crops(): Observable<any> {
    return this.cropsResponse.value$;
  }

  getCrops() {
    const req$ = this.httpClient.get<any>(
      `${this.agronicAPIBasePath}/api/v1/units/crops/all`,
      {}
    );
    handleResponse(req$, this.cropsResponse).subscribe((crops) => {
      this.cropsResponse.set(crops);
    });
  }
}
