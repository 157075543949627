import { Injectable } from "../di";

@Injectable('errorAccessService')
export class ErrorAccessService {
    private canAcces = false;
    
    canAccessErrorView(): boolean {
        return this.canAcces;
    }

    enableErrorViewAccess(): void {
        this.canAcces = true;
    }

    disableErrorViewAccess(): void {
        this.canAcces = false;
    }
}
