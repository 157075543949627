import { HttpMethod, HttpOptions } from "./httpClient";

export class HttpRequest<T> implements HttpOptions {
  body: T | null;
  url?: string;
  headers?: Headers | {[key: string]: string};
  method?: HttpMethod;
  observe?: 'body' | 'events' | 'response';
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;

  constructor(request: HttpRequest<T>) {
    this.body = request.body;
    this.url = request.url;
    this.headers = request.headers;
    this.method = request.method;
    this.observe = request.observe;
    this.reportProgress = request.reportProgress;
    this.responseType = request.responseType;
    this.withCredentials = request.withCredentials;
  }
}