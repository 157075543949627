import { BehaviorSubject, Observable, filter } from "rxjs";

export class VeggaResponse<T, U = unknown> {
    /**
   * Response value
   */
    private _response = new BehaviorSubject<T>(undefined);
    /**
     * Error value
     */
    private _error = new BehaviorSubject<U>(undefined);
    /**
     * Returns response value as unfiltered observable
     */
    get rawValue$(): Observable<T | undefined> {
        return this._response.asObservable();
    }
    /**
     * Returns response value as null values filtered observable
     */
    get value$(): Observable<T | undefined> {
        return this._response.asObservable().pipe(filter((response: T) => !!response));
    }
    /**
     * Return the synchronous value of the response.
     */
    get value(): T | undefined {
        return this._response.getValue();
    }
    /**
     * Returns error value as unfiltered observable
     */
    get rawError$(): Observable<U | undefined> {
        return this._error.asObservable();
    }
    /**
     * Returns response error as null values filtered observable
     */
    get error$(): Observable<U | undefined> {
        return this._error.asObservable().pipe(filter((response: U) => !!response));
    }
    /**
     * Emit the value to the response.
     * @param value Value to emit.
     * @param complete If is true complete the subscription.
     */
    set(value: T | undefined, complete?: boolean): void {
        this._response.next(value);

        if (complete) {
            this._response.complete();
        }
    }
    /**
     * Set an error.
     */
    setError(error: U | undefined, params: any): void {
        this._error.next(error);
    }
    /**
     * Set the response to undefined if have value.
     */
    clear(): void {
        this._response.next(undefined);
        this._response.complete();
        this._response = new BehaviorSubject(undefined);
    }
    /**
     * Clear the error.
     */
    clearError(): void {
        this._error.next(undefined);
        this._error.complete();
        this._error = new BehaviorSubject(undefined);
    }
}