import { BehaviorSubject, filter, Observable } from 'rxjs';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { AuthStore } from '../../vegga-front-store';

// exceptions can be a simple path as string, or
// an array of paths, to increase the specificity when matching
const URL_REDIRECT_EXCEPTIONS: string[] | string[][] = ['/irrigation-controls/exports'];

@Injectable('authFacade')
export class AuthFacade {
  constructor() {}
  @Inject('httpClient') private httpClient: HttpClient;
  private customAfterLoginUrlSubject = new BehaviorSubject<string>(null);
  private userPermissionsSubject = new BehaviorSubject<string[]>(null);

  get customAfterLoginUrl$(): Observable<string> {
    return this.customAfterLoginUrlSubject.asObservable().pipe(filter(u => !!u));
  }

  get customAfterLoginUrl(): string {
    return this.customAfterLoginUrlSubject.getValue();
  }

  get userPermissions$(): Observable<string[]> {
    return this.userPermissionsSubject.asObservable().pipe(filter(u => !!u));
  }

  get userPermissions(): string[] {
    return this.userPermissionsSubject.getValue();
  }

  /**
   * Allows to set customAfterLoginUrlSubject value
   * with an url to be saved, in order to use it
   * to redirect after user performs a sign in after being
   * redirected for unauth reasons.
   * @param url
   */
  setCustomAfterLoginUrl(url: string): void {
    const isException = URL_REDIRECT_EXCEPTIONS.some((exception: string | string[]) => {
      if (Array.isArray(exception)) {
        return exception.every(urlPath => url.includes(urlPath));
      } else {
        return url.includes(exception);
      }
    });

    this.customAfterLoginUrlSubject.next(isException ? url : null);
  }

  setUserPermissions(permissions?: string[]): void {
    // setting permissions from local storage when accessing app
    // without logging in
    if (!permissions) {
      const authStore = AuthStore.getInstance();
      authStore
        .get$()
        .pipe(filter(e => !!e))
        .subscribe(e => {
          this.userPermissionsSubject.next(e.organizations[0].permissions);
        });
      return;
    }

    // set user permissions after logging in
    this.userPermissionsSubject.next(permissions);
  }
}
